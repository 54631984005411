@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f7fa;
  margin: 0;
  padding: 0;
  color: #333;
}

.App {
  text-align: center;
  background-color: #f5f7fa;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.App-logo {
  height: 40px;
  margin-right: 10px;
  pointer-events: none;
}

.logo-container {
  display: flex;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 1.25rem;
  color: white;
  border-bottom: 1px solid #000;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.App-header h1 {
  font-size: 2rem;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 700; /* Apply bold font weight */
}

.App-link {
  color: #fbbf24;
  text-decoration: none;
  font-weight: bold;
}

.App-link:hover {
  text-decoration: underline;
}

nav {
  display: flex;
  gap: 1rem;
}

nav form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

nav form input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

nav button {
  background-color: #fbbf24;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  color: black;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
}

nav button:hover {
  background-color: #f59e0b;
}

button {
  background-color: #fbbf24;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  color: black;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
}

button:hover {
  background-color: #f59e0b;
}

section {
  margin: 0.5rem; /* Reduced vertical margin */
  padding: 0.5rem 0.5rem; /* Reduced vertical padding */
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
  /* Removed border */
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;
}

ul li:last-child {
  border-bottom: none;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.form-container label {
  display: block;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
}

.form-container input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
}

.slide-down {
  display: flex;
  animation: slide-down 0.5s ease-out forwards;
}

.message {
  transition: opacity 1s ease-in-out;
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
}

.fade-in {
  opacity: 1;
  animation: fadeIn 1s;
}

.fade-out {
  opacity: 0;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .App-header {
    font-size: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }

  main {
    flex-direction: column;
    padding: 0.5rem;
    width: 100%;
    box-sizing: border-box;
  }

  section {
    margin: 0.5rem 0;
    width: 100%;
    box-sizing: border-box;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
  }

  nav form input, nav button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .App-header {
    font-size: 0.875rem;
  }

  nav form input, nav button {
    font-size: 0.9rem;
  }
}

.main-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.section-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.section-container h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
}

.content-box {
  text-align: left;
}

.content-box ul {
  list-style-type: none;
  padding: 0;
}

.content-box li {
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
}

.course-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.module {
  margin-bottom: 10px; /* Reduced vertical margin */
  padding: 10px;
  border: 1px solid #ddd; /* Added border */
  border-radius: 4px;
  background-color: #f9f9f9;
}

.module h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
}

.module p {
  font-size: 1em;
  line-height: 1.5;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 90%;
  max-width: 500px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}
