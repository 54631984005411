.top-left {
  position: relative;
  margin-top: 20px; /* Adjust this value based on your header height */
  left: 0;
  display: flex;
  gap: 10px;
}

.content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modules {
  width: 30%;
}

.modules ul {
  list-style-type: none;
  padding: 0;
}

.modules li {
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modules li:hover {
  background-color: #e0e0e0;
}

.checkmark {
  color: green;
  font-weight: bold;
}

.module-content {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.command-section {
  width: 65%;
}

.command-form {
  display: flex;
  align-items: center;
}

.command-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  flex: 1;
}

.command-submit {
  padding: 10px 20px;
  margin-left: 10px;
  background-color: #fbbf24;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  color: black;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
}

.command-submit:hover {
  background-color: #f59e0b;
}

.command-output {
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
  height: 300px;
  overflow-y: auto;
  text-align: left; /* Ensure left alignment */
}

.course-completion {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  text-align: center;
}

.course-completion h3 {
  margin-bottom: 20px;
}

.course-completion button {
  margin: 5px;
}

/* Стили перенесены в App.css */
